;(function($) {

  'use strict'; // Using strict mode

  // Vertical lines
  $('body').append('<div class="l1"></div><div class="l2"></div><div class="l3"></div>')

  // Page transitions

  // $('a[href!=#][data-toggle!=tab][data-toggle!=collapse][target!=_blank][class!=anchor]').addClass('smooth');

  $('.smooth-transition').animsition({
    linkElement: '.smooth',
    inDuration: 500,
    outDuration: 500,
  });

  $('html').on('click', function(e){
    $('.navigation, .nav-trigger').removeClass('tapped');
  });

  $('.nav-trigger').on('click', function(e){
    e.stopPropagation();
    $('.navigation').toggleClass('tapped');
    if($('.navigation').hasClass('tapped'))
      $('.nav-trigger').addClass('tapped');
    else
      $('.nav-trigger').removeClass('tapped');
  });

  $('.navigation li').on('click', function(e){
    e.stopPropagation();
    $(this).toggleClass('tapped');
  });

  // Grid functions

  var $grid = $('.grid');

  $grid.imagesLoaded(function(){
    // Initialize Masonry after the images are loaded
    $grid.packery({
      itemSelector: '.item', // Portfolio item
    });
  });

  $('.filter-trigger').on('click', function(e){
    e.preventDefault();
    $('body').addClass('filters-active');
    $('html,body').animate({
      scrollTop: $('.grid').offset().top+'px'
    }, 500);
    $('.filter-container').fadeIn();
  });

  $('.filter-container').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('filters-active');
    $('.filter-container').fadeOut();
  });

  $('.filter').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    var selected = $(this).attr('data-filter');
    $('.filter.active').removeClass('active');
    $(this).addClass('active');
    $('.grid').find('.item:not(.'+selected+')').css({
      '-webkit-transition' : 'all .25s',
      'transition' : 'all .25s',
      '-webkit-transform' : 'scale(0)',
      'transform' : 'scale(0)',
      '-webkit-opacity' : '0',
      'opacity' : '0',
    });
    setTimeout(function(){
      $('.grid').find('.item:not(.'+selected+')').hide(0);
      $('.grid').find('.'+selected).show(0).css({
        '-webkit-transform' : 'scale(1)',
        '-webkit-opacity' : '1',
        'transform' : 'scale(1)',
        'opacity' : '1'
      });
      $grid.packery('layout');
    }, 250);
  });

  $(window).on('resize', function(){
    // Change Masonry on resize
    setTimeout(function(){
      $grid.packery('layout');
      window.requestAnimationFrame(inView); // Make new items visible
    }, 1500);
  });

  // You can use anchor links, using the .anchor class
  $('.anchor').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    var href = $(this).attr('href');
    $('html,body').animate({
      scrollTop : $(href).offset().top+'px'
    });
  });

  // Parallax background script, use the ".parallax" class.
  var parallaxSpeed = 0.15;

  function parallax(){
    // Parallax scrolling function
    $('.parallax').each(function(){
      var el = $(this);
      var yOffset = $(window).scrollTop(),
          parallaxOffset = yOffset * parallaxSpeed,
          parallaxOffset = +parallaxOffset.toFixed(2);
      if(el.hasClass('fs')){
        el.css('transform','translate3d(-50%,-'+(50-parallaxOffset*0.15)+'%,0)');
      } else {
        el.css('transform','translate3d(0,'+parallaxOffset+'px,0)');
      }
    });
  }

  // Initialize functions on scroll
  $(window).on('scroll', function(){
    window.requestAnimationFrame(parallax); // Parallax
  });

  var $animation_elements = $('.item, .fadein'); // The fly-in element, used for elements that fly-in the window after they're visible on screen

  function inView() { // Function when element is in view
    var window_height =   $(window).height();
    var window_top_position =   $(window).scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top-100;
      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport
      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
        $element.addClass('in-view');
      } else {
        $element.removeClass('in-view');
      }
    });
  }

  $(window).on('scroll resize', function(){
    window.requestAnimationFrame(inView);
    $('.anchor').each(function(){
      var id = '#'+$('.in-view').attr('id');
      if(id == $(this).attr('href')){
        $('.anchor').removeClass('active');
        $(this).addClass('active');
      }
    });
  });

  $(window).on('load', function(){
    window.requestAnimationFrame(inView);
  });

  $(window).on('pageshow', function(event) {
      if (event.originalEvent.persisted) {
          window.location.reload()
      }
  });

})(jQuery);

// const instance = new TypeIt('.typed',  {

//   speed: 100,
//   waitUntilVisible: true,
//   deleteSpeed: 100,
//   loopDelay: 1000,
//   loop: true
// }).pause(2000).delete(10).pause(400).type(' Hello')
// .pause(2000).delete(10).pause(300).type(' Hola').pause(2000).go();
// $(".typed").typed({strings: ["Hello", "Hola", "こんにちは", "Ciao", "Aloha", "Hallo"],typeSpeed: 130,loop: true  });

var EMA = EMA || {};

EMA.BUILD = (function() {
    return {
        init: function() {
            // Find all controller in page
            // Define template or content

            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // Convert IMG to SVG source code inline
            this.svg('img.svg-convert');

            // Load Lazy Images with animation
            this.lazyLoader();
        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: -100,
                success: function(element) {
                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 100);
                }
            });

            return bLazy;

        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        },
        parallax: function(image, speed) {
            if (Foundation.MediaQuery.atLeast('medium')) {
                image.css('transform', 'translate3D(0, ' + (EMA.BUILD.resize().scrollTop * speed) + 'px, 0)');
            }
        }
    };
})();

EMA.HOME = (function() {
  return {
      init: function() {
        console.log("home");
      }
    }
})();

EMA.CLASSE_MANAGER = (function() {
  return {
      init: function(elem) {
        console.log("CLASSE_MANAGER");
        $('#example').dataTable({
          language: { search: "", sSearchPlaceholder: "Procurar" },
          'olanguage': { 'sSearch': '', 'sSearchPlaceholder': "Search..." },
          paging: false,
          columnDefs: [
            { "visible": false, "targets": 0 },
            {
              orderable: false,
              targets: [4]
              }
          ],
        });
        //setup before functions
        var typingTimer;                //timer identifier
        var doneTypingInterval = 500;  //time in ms, 5 second for example
        var $input = $('.name-td input');
        var _originalVal;
        //on keyup, start the countdown
        $input.on('focus', function (e) {
          _originalVal = $(e.currentTarget).val();
        });

        $input.on('keyup', function (e) {
          
          clearTimeout(typingTimer);
          typingTimer = setTimeout(doneTyping.bind(null, e), doneTypingInterval);
          
        });

        //on keydown, clear the countdown 
        $input.on('keydown', function (e) {
          
          clearTimeout(typingTimer);
          if( e.which == 9 ) {
            
            doneTyping(e);
          }
          
        });

        //user is "finished typing," do something
        function doneTyping (e) {
          if(($(e.currentTarget).val() != _originalVal)) {
            let _val = $(e.currentTarget).val();
            let _id = $(e.currentTarget).data("id");
            let _name = $(e.currentTarget).attr("name");
            let userVO = {"ID": _id, [_name]: _val, "action": "editUserBasicInfo"};
            console.log(userVO);
            var json = JSON.stringify(userVO);
            if(_val != "") {
              editUserBasicInfo(json, function(e){
                
                ZToast(e.label);
                
              });
            }
          }
        }
        $('.remove-user').on("click", function(){
          let _userID = $(this).data("user-id");
          EMA.OVERLAY.show();
          $.post('/frontend/engine/engine.php', {
            'action': 'deleteUsersFromContent',
            'contentUID': $(elem).data("course-id"),
            'userID': _userID
          }, function(data, status, xhr) {
            if (data) {
              ZToast(data.label);
              if(data.kind != "error") {
                setTimeout(function(){
                  location.reload();
                }, 1000);
              }
            } else {
                console.error(status, xhr);
            }
          }, 'json').fail(function(xhr, textStatus, errorThrown) {
              console.error(xhr, textStatus, errorThrown);
          });
        })
        $('.payed-status-btn').on("click", function(){
          let _userID = $(this).data("user-id");
          EMA.OVERLAY.show();
          $.post('/frontend/engine/engine.php', {
            'action': 'setManualOrder',
            'contentUID': $(elem).data("course-id"),
            'userID': _userID
          }, function(data, status, xhr) {
            if (data) {
              location.reload();
            } else {
                console.error(status, xhr);
            }
          }, 'json').fail(function(xhr, textStatus, errorThrown) {
              console.error(xhr, textStatus, errorThrown);
          });
        })
        
        $('.nopayed-status-btn').on("click", function(){
          EMA.OVERLAY.show();
          $.post('/frontend/engine/engine.php', {
            'action': 'deleteOrder',
            'orderID': $(this).data("order-id")
          }, function(data, status, xhr) {
            if (data) {
              
              location.reload();
            } else {
                console.error(status, xhr);
            }
          }, 'json').fail(function(xhr, textStatus, errorThrown) {
              console.error(xhr, textStatus, errorThrown);
          });
        })

        $('.certificate-btn').on("click", function(e){
          e.preventDefault();
          
          let _data = {action: "updateRelationTag", parentTable: "contents", childTable: "usersWebsite", parentID: $(this).data("content-uid"), childID:$(this).data("user-id"), tag: $(this).data("tag") };
          engineInterface(_data, function(e){
            // console.log(e);
            EMA.OVERLAY.show();
            location.reload();
          })
        })
        $('.send-mail').on("click", function(e){
          EMA.OVERLAY.show();
          e.preventDefault();
          $.post('/frontend/engine/engine.php', {
            'action': 'SendWarning',
            'contentUID': $(this).data("content-uid"),
            'user': $(this).data("user-id"),
            'subject': $(this).data("subject")
          }, function(data, status, xhr) {
            if(data) {
              EMA.OVERLAY.hide();
              ZToast("Email enviado.");
            }
          }, 'json').fail(function(xhr, textStatus, errorThrown) {
              console.error(xhr, textStatus, errorThrown);
          });
        })

        $('.send-mail-payment-warning').on("click", function(e){
          EMA.OVERLAY.show();
          e.preventDefault();
          var allid = $('.payed-status-btn').map(function(){
            return $(this).data('user-id');
          }).toArray();
          // console.log(allid); 
          
          $.post('/frontend/engine/engine.php', {
            'action': 'SendWarning',
            'contentUID': $(this).data("content-uid"),
            'user': allid,
            'label': $(this).data("label"),
            'subject': $(this).data("subject")
          }, function(data, status, xhr) {
            if(data) {
              EMA.OVERLAY.hide();
              ZToast("Email enviado.");
            }
          }, 'json').fail(function(xhr, textStatus, errorThrown) {
              console.error(xhr, textStatus, errorThrown);
          });
        })

        
        
      }
    }
})();

EMA.OVERLAY = (function(el) {
  var self;
  var elem;
  var spin;

  return {
      init: function(el) {
          self = this;
          elem = $(el);
          spin = $(el).find(".preloader-wrapper-container");
          console.log("OVERLAY");
      },
      show: function(callback){
          gsap.to(elem, .3, {autoAlpha: 1, onComplete: callback});
      },
      hide: function(callback){
          gsap.to(elem, .3, {autoAlpha: 0, onComplete: callback});
      }
  }
})();
EMA.STUDENT_FORM = (function() {
  
  return {
      init: function(elem) {
          console.log("STUDENT_FORM");
          var _elem = elem;
          $(elem).on('formvalid.zf.abide', function(event, form) {
            
              var allInputs = form.serializeArray();
              
              for (var i = 0; i < allInputs.length; i++) {

                  var data = $(form).find('[name=' + allInputs[i].name + ']');

                  if (data.data('info') === undefined) {
                      allInputs[i].info = allInputs[i].name;
                  } else {
                      allInputs[i].info = data.data('info');
                  }
              }
              var formData = {};
              var formObjects = $("form :input");
              formObjects.each(
              function(){
                if($(this).attr("name") !== undefined) {
                  formData[$(this).attr("name")] = $(this).val(); /*setting up name/value pairs */ 
                }
              });

              console.log(formData);
                            

              $.post('/frontend/engine/engine.php', {
                  'action': 'setUserToContent',
                  'contentUID': $(elem).data("course-id"),
                  'userVO': formData
              }, function(data, status, xhr) {
                  if (data) {
                    ZToast(data.label);
                    if(data.kind == "success") {
                      setTimeout(function(){
                        location.reload();
                      }, 1000);
                    }
                  } else {
                      console.error(status, xhr);
                  }
              }, 'json').fail(function(xhr, textStatus, errorThrown) {
                  console.error(xhr, textStatus, errorThrown);
              });

          }).on('submit', function(event) {
              event.preventDefault();
          });
      }
  };
})();

EMA.ZIPLINK = (function() {
  var _uid; 
  var _elem;
  var _fileName;
  return {
      init: function(elem) {
        _elem = elem;
        $(elem).on("click", this.clickHandler);
        

      },
      clickHandler: function(e){
        e.preventDefault();
        _uid = $(e.currentTarget).data("uid");
        _fileName = $(e.currentTarget).data("file-name");
        console.log(_uid);
        $.post('/frontend/engine/engine.php', {
          'action': 'zipContentFiles',
          'UID': _uid,
          "ZipFileName": _fileName,
          "overwrite": true
        }, function(data, status, xhr) {
          console.log(data);
            if(data == true) {
              window.open("/frontend/contents/"+_fileName+".zip");
              // _elem.click();
            }
            // if (data) {
            //     console.log(data);
            // } else {
            //     console.error(status, xhr);
            // }
        })

      }
  };
})();

EMA.LOGIN = (function(el) {
  var _this;
  var _el;
  var _type;
  return {
      init: function(el) {
        _this = this;
        _el = el;
        console.log("login",el);
        $("#form-selector-btn").on("click",function(e){
          e.preventDefault();
          _this.showRecover();
        })
        $("#form-login-btn").on("click",function(e){
          e.preventDefault();
          _this.showLogin();
        })
        _type = new TypeIt('.typed',{

          speed: 100,
          waitUntilVisible: true,
          deleteSpeed: 50}).go();
      },
      showRecover: function(){
        _type.delete(12).pause(100).type('Receber pass').go();
          
         

        let formRecover = $("#form-get-password");
        let formLogin = $("#form-login");
        
        gsap.to(formLogin, .3, {autoAlpha: 0, display:'none'});
        gsap.to(formRecover, .5, {autoAlpha: 1, display:'block', delay: .3});
      }
      ,
      showLogin: function(){
        _type.delete(12).pause(100).type('Login').go();
        let formRecover = $("#form-get-password");
        let formLogin = $("#form-login");
        
        gsap.to(formRecover, .3, {autoAlpha: 0, display:'none'});
        gsap.to(formLogin, .5, {autoAlpha: 1, display:'block', delay: .3});
      }
    }
})();

EMA.COURSE_FORM = (function() {
  return {
      init: function(elem) {
          
          console.log("form_course")
          $(elem)
              .on('formvalid.zf.abide', function(event, form) {
                let _form = form.serializeArray().reduce(function(obj, item) {
                  obj[item.name] = item.value;
                  return obj;
              }, {})
                console.log("entrou",_form);
                $.post('/frontend/engine/engine.php', {
                    'action': 'getCourseForEmail',
                    'form': _form
                }, function(data, status, xhr) {
                  console.log(data);
                  $(".status_form_course").html(data.label);
                    // if (data) {
                    //     console.log(data);
                    // } else {
                    //     console.error(status, xhr);
                    // }
                })

              })
              .on('submit', function(event) {
                event.preventDefault();
              });

      }
  };
})();

EMA.FORM_EDIT_USER = (function() {
  return {
      init: function(elem) {
          
          console.log("FORM_EDIT_USER");
          $(elem)
              .on('formvalid.zf.abide', function(event, form) {
                let _form = form.serializeArray().reduce(function(obj, item) {
                  obj[item.name] = item.value;
                  return obj;
              }, {})
                console.log("entrou",_form);
                $.post('/frontend/engine/engine.php', {
                    'action': 'editUserBasicInfo',
                    'firstName': _form.firstName,
                    'lastName': _form.lastName,
                    'phone': _form.phone,
                    'address': _form.address,
                    'alternativeZipcode': _form.alternativeZipcode,
                    'zipcodeZone': _form.zipcodeZone,
                    'job': _form.job,
                    'ID': _form.id
                }, function(data, status, xhr) {
                  console.log(data);
                  $(".status-message").html(data.label);
                    if (data.kind == "success") {
                      location.reload();
                    } 
                })

              })
              .on('submit', function(event) {
                event.preventDefault();
              });

      }
  };
})();

$(function() {
  // Call when document is ready
  $(document).foundation();
  EMA.BUILD.init();
});
